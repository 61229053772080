$badge-text-white: #ffe;
$badge-neutral-bg: #91979b;
$badge-neutral-text: #555;
$badge-warning-bg: #bb2742;
$badge-warning-text: #bb2742;
$badge-warning-text: #bb2742;

$badge-blue-bg: #087de7;
$badge-green-bg: #25cb2f;
$badge-red-bg: #bb2742;
$badge-amber-bg: #e18d0e;

%small-badge {
  align-content: space-between;
  align-self: center;
  border: solid 1px $badge-neutral-bg;
  border-radius: 5px;
  color: $badge-neutral-text;
  display: flex;
  font-size: smaller;
  font-variant: small-caps;
  font-weight: bold;
  margin-right: 0.5em;
  padding: 1px 4px;
}

.badge-profile-role,
.activity-badge-info,
.badge-status-info {
  background-color: #0b6162;
  color: #fff;
}

.activity-badge-success,
.badge-status-success {
  background-color: #006400;
  color: #fff;
}

.activity-badge-primary,
.badge-status-primary {
  background-color: #194a7a;
  color: #fff;
}

.small-badge {
  align-content: space-between;
  align-self: center;
  border: solid 1px $badge-neutral-bg;
  border-radius: 5px;
  color: $badge-neutral-text;
  display: inline-flex;
  font-size: small;
  margin-right: 0.5em;
  overflow: hidden;
  padding: 1px 4px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.small-badge-no-border {
  align-content: space-between;
  align-self: center;
  border: solid 0 $badge-neutral-bg;
  border-radius: 2px;
  color: $badge-neutral-text;
  display: inline-flex;
  font-size: smaller;
  font-variant: small-caps;
  font-weight: bold;
  margin-right: 0.3em;
  padding: 2px;
}

.small-badge-id {
  @extend %small-badge;
}

.addition-badge {
  @extend %small-badge;
  background-color: $badge-green-bg;
  border-color: $badge-green-bg;
  color: $badge-text-white;
  display: inline-flex;
  font-variant: normal;
  justify-content: left;
}

.modification-badge {
  @extend %small-badge;
  background-color: $badge-amber-bg;
  border-color: $badge-amber-bg;
  color: $badge-text-white;
  display: inline-flex;
  font-variant: normal;
}

.deletion-badge {
  @extend %small-badge;
  background-color: $badge-red-bg;
  border-color: $badge-red-bg;
  color: $badge-text-white;
  display: inline-flex;
  font-variant: normal;
}

.initial-badge {
  @extend %small-badge;
  background-color: $badge-blue-bg;
  border-color: $badge-blue-bg;
  color: $badge-text-white;
  display: inline-flex;
  font-variant: normal;
}

.warning-badge {
  @extend %small-badge;
  background-color: $badge-warning-bg;
  border-color: $badge-warning-bg;
  color: $badge-text-white;
}
