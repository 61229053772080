.dashboard-header {
  align-items: flex-end;
  display: flex;
  padding-bottom: 1em;
  padding-right: 0.25em;

  .dashboard-actions {
    align-self: flex-end;
    display: flex;
    margin-left: auto;

    a.dashboard-create-link {
      text-decoration: none;
    }
  }
}
