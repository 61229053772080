$help-block-background: #dff0d8;
$help-block-color: #006311;
$memorable-date-max-width: 470px;
$toc-indent: 1rem;

.input-group.narrow {
  @include media-breakpoint-up(sm) {
    width: 160px;
  }
}

.form-group.narrow {
  input,
  select,
  textarea {
    @include media-breakpoint-up(sm) {
      max-width: 160px;
    }
  }
}

.rjsf {
  #root__title {
    display: none;
  }

  /* Override CSS for error text */
  .text-danger {
    color: #a80000 !important;
  }

  /* Override CSS for alt-date */
  .list-inline > li {
    display: inline-block;
  }

  input[type='file'] {
    border: 1px solid $border-color;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    padding: 0.3rem;
    width: 100%;
  }

  legend {
    border-bottom: 1px solid $border-color;
    font-size: 110%;
    margin-bottom: 1rem;
    padding-bottom: 0.3rem;
    padding-top: 0.3rem;
    page-break-inside: avoid;
  }

  .control-label {
    font-weight: bold;
  }

  /* stylelint-disable scss/at-extend-no-missing-placeholder */
  .error-detail {
    @extend .alert;
    @extend .alert-danger;
    background: #f2e1e2 !important;
    list-style: none;
    margin-top: 0.3rem;
  }

  /* stylelint-enable scss/at-extend-no-missing-placeholder */

  .memorable-date-field {
    max-width: $memorable-date-max-width;
  }

  select.form-control {
    width: auto;
  }

  .radio-inline {
    padding-right: 2rem;
  }

  input[type='checkbox'],
  input[type='radio'] {
    margin-right: 0.5rem;
  }

  .form-control + .help-block {
    margin-top: 0.5rem;
  }

  .help-block,
  .help-block-html {
    background-color: $help-block-background;
    border: 1px solid $border-color;
    color: $help-block-color;
    padding: $input-padding-y $input-padding-x;
  }

  // Override default input group button style
  // Make is the same as in search
  .input-group-append {
    .btn {
      background-color: $input-bg;
      border-color: $input-border-color;
      color: $input-color;
    }

    svg {
      display: block;
      height: 1rem;
      width: 1rem;
    }
  }

  .hidden-field {
    display: none;
  }

  .form-group-help {
    margin-bottom: 1rem;
  }

  .form-group + .form-group-help {
    margin-top: -0.5rem;
  }

  .field-description {
    margin-bottom: 0.5rem;
  }

  input[type='date'] {
    width: auto;
  }

  input[id$='_email'],
  input[id$='_name'],
  input[id$='_phone'] {
    min-width: 20rem;
    width: auto;
  }

  input[id$='_email'] {
    min-width: 25rem;
    width: auto;
  }

  input[type='number'] {
    width: auto;
  }

  .row > .array-item {
    flex-grow: 1;
  }

  .array-item {
    border: 1px dashed $border-color;
    padding: 0.5rem 1rem;
  }

  .array-item-buttons {
    border-top: 1px dashed $border-color;
    margin: -1rem -1rem 0.5rem;
    padding: 1rem 1rem 0;
  }

  .array-item-add-section {
    margin-top: 1rem;
  }

  /* stylelint-disable max-nesting-depth, selector-no-qualifying-type, selector-max-compound-selectors */
  /* Every form starts with this #root fieldset */
  fieldset#root {
    fieldset {
      // first level fieldset, leave it alone as it is part of root
      fieldset {
        border-left: 2px solid $light;
        // second level
        padding-left: 1rem;

        fieldset {
          border-left: 2px solid $light;
          // third level
          padding-left: 1rem;

          fieldset {
            border-left: 2px solid $light;
            // fourth level
            padding-left: 1rem;
          }
        }
      }
    }
  }

  .radio-with-deprecation {
    fieldset {
      border-left: 0 !important;
      padding-left: 0 !important;

      label {
        margin-bottom: 0.2rem;
      }
    }
  }

  /* stylelint-enable max-nesting-depth, selector-no-qualifying-type, selector-max-compound-selectors */
}

.read-only-form {
  .h1 {
    font-size: 120%;
  }

  .h2 {
    font-size: 110%;
  }

  .h3,
  .h4,
  .h5,
  .h6 {
    font-size: 100%;
  }

  // Table of Contents styles
  @for $i from 1 through 6 {
    $indent-level: $i - 1; // zero indent on first level
    .toc-#{$i} {
      padding-left: $indent-level * $toc-indent;
    }
  }

  .form-heading {
    border-bottom: 2px solid #ced4da;
    padding-top: 10px;
  }

  .form-field {
    border: 1px solid #ced4da;
    page-break-inside: avoid;
  }

  .form-field-label {
    background-color: #f0f0f0;
    border-bottom: 1px solid #ced4da80;
    padding: $input-padding-y $input-padding-x;
  }

  .form-field-description {
    background-color: #f9f9f9;
    border-bottom: 1px solid #ced4da80;
    color: $gray-600;
    font-size: $small-font-size;
    font-style: italic;
    padding: $input-padding-y $input-padding-x;
  }

  .form-field-value {
    padding: $input-padding-y $input-padding-x;
  }

  .form-field-value:empty::after {
    content: '.';
    visibility: hidden;
  }
}

.form-modal {
  .search-form {
    select {
      max-width: 12rem;
    }
  }

  .badge-block {
    border-radius: 15px;
    display: block;
    font-size: 80%;
    font-weight: normal;
    margin-top: 3px;
    padding: 4px 10px;
  }

  .rjsf select.form-control {
    width: 100%;
  }
}

.form-page-modal {
  .rjsf select.form-control {
    width: 100%;
  }
}

.form-flow-modal-height {
  min-height: 30vh;
}

.suggestion-item.disabled {
  text-decoration: line-through;
}

.notes {
  h1 {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 1em;
    margin-top: 1.5em;
    padding-bottom: 0.5em;
  }

  h2 {
    margin-bottom: 1em;
  }

  h3 {
    margin-bottom: 1em;
  }

  h4,
  h5,
  h6 {
    font-weight: bold;
  }
}

/*
  Style for dynamic output elements within various forms
 */
.form-output-message {
  border-radius: 4px;
  color: $help-block-color;
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: $input-padding-y $input-padding-x;

  p.title {
    align-items: center;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    font-weight: bold;
    justify-content: center;
    margin: 0;
  }

  p.note {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    justify-items: left;
    margin: 0;
  }

  ul {
    margin-top: 1em;
  }

  .variant-one {
    background-color: #c44601;
    color: #fff;
  }

  .variant-two {
    background-color: #054fb9;
    color: #fff;
  }

  .variant-three {
    background-color: #b51963;
    color: #fff;
  }
}

p.plain-guidance {
  font-size: 0.9em;
  margin-top: 0.5em;
}

.filter-border {
  align-items: center;
  background-color: #f1f1f1;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 10px #eee;
  display: flex;
  justify-content: flex-start;
  padding: 0.5em;
  padding-bottom: 5px;
}

.filter {
  height: 31px;
}

.filter:disabled {
  background-color: #808080;
  border: #808080;
  cursor: not-allowed;
}
