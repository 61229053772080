@mixin svg-gradient-variant($parent, $color) {
  #{$parent} {
    fill: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg'><linearGradient id='grad' gradientTransform='rotate(-30)'><stop offset='0%' stop-color='#{rgba(darken($color, 5%), 0.9)}'/><stop offset='70%' stop-color='#{rgba(lighten($color, 8%), 0.9)}'/><stop offset='100%' stop-color='#{rgba(lighten($color, 5%), 0.9)}'/></linearGradient></svg>#grad") $color;
  }
}

@mixin text-gradient-variant($parent, $color) {
  #{$parent} {
    background: $color linear-gradient(to right, darken($color, 15%), lighten($color, 20%));
    background-clip: text;
    box-decoration-break: clone;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
  }
}

@each $color, $value in $theme-colors {
  @include svg-gradient-variant('.svg-gradient-#{$color}', $value);
  @include text-gradient-variant('.text-gradient-#{$color}', $value);
}
