div.collapsible {
  display: flex;
  float: left;
  padding: 0;
  width: 100%;
}

div.collapsible:hover {
  font-weight: lighter;
}

.collapsible-header {
  align-content: center;
  display: flex;
  justify-content: space-between;
}

.collapsible-header .header {
  align-self: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-right: 0.25em;
  text-decoration: none;
  width: 100%;
}

.collapsible-header .title {
  align-self: center;
  cursor: pointer;
  display: flex;
  text-decoration: none;
}

.collapsible-header .caret {
  align-self: center;
  color: #707070;
  cursor: pointer;
  display: flex;
  margin-right: 0.25em;
}

.collapsible .caret-right {
  cursor: pointer;
}

.collapsible > a {
  text-decoration: none;
}
