// Component loaders
// -----------------

.loading-text-filler {
  animation: loader-move 1s linear infinite;
  background-color: #f0f0f0;
  background-image: linear-gradient(-45deg,
  rgba(255, 255, 255, 0.2) 25%,
  transparent 25%,
  transparent 50%,
  rgba(255, 255, 255, 0.2) 50%,
  rgba(255, 255, 255, 0.2) 75%,
  transparent 75%,
  transparent);
  background-size: 50px 50px;
  display: inline-block;
  height: 1em;
  width: 100%;
}

@keyframes loader-move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}
