.activity-list-timestamp {
  font-size: smaller;
  margin-left: 0.2em;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}

.activity-actor {
  font-size: smaller;
  margin-left: 0.2em;
}

span.activity-badge {
  font-size: smaller;
}

.activity-subject-ident {
  padding-left: 1em;
}

.activity-task-display-name {
  padding-right: 0.5em;
}

.activity-task-status-text {
  margin-left: -0.25em;
}
