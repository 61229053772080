.referenced-case-link {
  align-self: center;
  display: flex;
  padding: 0.5em 1em;
}

.referenced-case-status {
  align-self: center;
  display: flex;
  padding: 0.5em 1em;
}


.referenced-task-row {
  display: flex;
  justify-content: left;
  padding: 0.1em 1em;
}

.child-task-icon {
  color: #6c757d;
  margin-left: 20px;
}

.referenced-task-link {
  align-self: center;
  display: flex;
  padding: 0.1em 1em;
}

.referenced-task-status {
  align-self: center;
  display: flex;
  padding: 0.1em 1em;
}
