.related-case-row {
  align-content: flex-start;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
}

.case-link-container {
  align-content: center;
  display: flex;
}

.case-link-container:hover > .small-badge,
.case-link-container:hover > .small-badge-id {
  color: #67299f;
}

.case-link-icon {
  align-self: center;
  color: #0073e6;
  display: flex;
  padding-right: 0.25em;
}

.case-link-detail {
  align-self: center;
  display: flex;
}

.case-link-details-row {
  display: flex;
  flex-direction: column;

  div.row-label {
    display: flex;
    font-size: smaller;
    font-weight: bold;
  }

  div.row-value {
    display: flex;
    font-size: smaller;
    padding-left: 0.5em;
  }

}

%case-badge {
  align-content: space-between;
  align-self: center;
  border: solid 1px #91979b;
  border-radius: 5px;
  color: #91979b;
  display: flex;
  font-size: smaller;
  font-variant: small-caps;
  font-weight: bold;
  margin-right: 0.5em;
  padding: 1px 4px;
}

.case-badge {
  align-content: space-between;
  align-self: center;
  border: solid 1px #91979b;
  border-radius: 5px;
  color: #91979b;
  display: flex;
  font-size: smaller;
  font-variant: small-caps;
  font-weight: bold;
  margin-right: 0.5em;
  padding: 1px 4px;
}

.case-badge-id {
  @extend %case-badge;
  font-size: x-small;
}

.related-case-label {
  margin-right: 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.related-case-actions-row {
  padding-bottom: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.related-case-status-row {
  padding-bottom: 0.5em;
}

.case-status-container {
  align-content: space-evenly;
  display: flex;
  font-size: small;
}

.case-status-item {
  align-self: center;
  display: flex;
  justify-self: left;
  margin-right: 1em;
}

.case-status-item > .colour-dot {
  align-self: center;
  display: flex;
  min-width: 12px;
}

.case-status-label {
  align-self: center;
  display: flex;
  white-space: pre;
}

.embedded-case-link {
  padding-top: 1em;
}

dd.case-summary > dt.label {
  font-weight: bold;
}

dt.value {
  font-weight: normal;
}

.case-list-item-container {
  display: flex;
  flex-direction: column;
}

.case-list-item-container .link {
  display: flex;
  padding-bottom: 0.25em;
}

.case-list-item-container .status {
  display: flex;
  justify-content: right;
  padding-bottom: 0.25em;
}

.case-list-item-container .details {
  display: flex;
  flex-direction: column;
}

.case-list-item-container .supplementary {
  display: flex;
  flex-direction: column;
  font-size: small;
}

.case-list-item-container .details .details-row {
  display: flex;
  flex-direction: row;
  font-size: smaller;
  padding-bottom: 0.25em;
}

.case-list-item-container .details .details-row .details-row-label {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  padding-right: 0.25em;
}

// specific to the layout of the external case display page
// (may be combined with general case display styles in the future)
.external-case-summary {
  display: flex;
  flex-direction: column;
}

.external-case-summary .heading {
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
}

.external-case-summary .heading-padded {
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  padding-top: 1em;
}

.external-case-summary .field-row {
  align-content: space-between;
  display: flex;
  flex-direction: row;
  padding-top: 0.5em;
}

.external-case-summary .field-row .label {
  display: flex;
  font-weight: bold;
  padding-left: 0.5em;
  width: 25%;
}

.external-case-summary .field-row  .value {
  display: flex;
  width: 75%;
}


