.search-form {
  .input-group-append {
    .btn {
      background-color: $input-bg;
      border-color: $input-border-color;
      color: $input-color;
    }

    svg {
      display: inline-block;
    }
  }
}

.search-widget-container {
  align-content: space-between;
  display: flex;
  flex-direction: column;
}

.search-widget-container-row {
  align-content: space-between;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.search-widget {
  align-content: center;
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  justify-items: left;
  padding-bottom: 0.25em;
}



.search-widget:not(:first-child) {
  border-left: 0;
}

.search-widget:not(:last-child) {
  border-right: 0;
}

.search-widget-label {
  border-bottom-right-radius: 0;
  border-right: 0;
  border-top-right-radius: 0;
  flex-basis: auto;
  font-size: smaller;
  min-width: 4em;
  overflow-y: auto;
}

.search-widget-field {
  border: 1px solid #ced4da;
  border-left: 0;
  border-radius: 0 0.25em 0.25em 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  display: flex;
  flex: 1;
  font-size: smaller;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  width: 100%;
}

.search-container {
  display: flex;
  flex-direction: column;
  gap: 0.25em;
}

.search-results-centered-container {
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 640px;
  min-height: 640px;
}

.search-results-centered-container > span {
  display: flex;
  justify-content: center;
}

.search-results-centered-container .warning {
  color: #6c757d;
  height: 48px;
  width: 48px;
}

.search-results-header {
  display: flex;
  flex-direction: row;
  font-size: smaller;
  justify-content: right;
}

.search-results-container {
  display: flex;
  flex-direction: column;
  max-height: 640px;
  min-height: 640px;
  overflow-y: scroll;
}

.search-result-item {
  border-bottom: 1px solid #9ba0a5;
  display: flex;
  flex-direction: column;
  padding: 0.25em;
}

.search-result-item:hover {
  background: #e0e5e5;
  cursor: pointer;
}

.search-result-item .link {
  display: flex;
  padding-bottom: 0.25em;
}

.search-result-item .status {
  display: flex;
  justify-content: right;
  padding-bottom: 0.25em;
}

.search-result-item .details {
  display: flex;
  flex-direction: column;
}

.search-result-item .details .details-row .row-label {
  font-weight: bold;
}

.search-result-item .details .details-row .row-value {
  padding-left: 0.25em;
}

.search-result-item .supplementary {
  display: flex;
  flex-direction: column;
  font-size: small;
}

.search-result-item .details .details-row {
  display: flex;
  flex-direction: row;
  font-size: smaller;
  padding-bottom: 0.25em;
}

.search-result-item .details .details-row .details-row-label {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  padding-right: 0.25em;
}

.sidebar-search {
  align-content: center;
  border-bottom: 1px solid $border-color;
  column-gap: 0.25em;
  display: flex;
  flex-direction: column;
}

.quick-search-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 16px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.25em;
}

.quick-search-container .search-widget {
  align-items: center;
  height: 100%;
}

.quick-search-container .search-widget .action {
  cursor: pointer;
}

svg.warning {
  color: #dc1134;
}

.quick-search-container .search-widget-field {
  align-self: center;
}

.quick-search-container  input {
  border-bottom-right-radius: 0;
  border-right: 0;
  border-top-right-radius: 0;
}

.quick-search-action {
  align-content: center;
  display: flex;
  height: 100%;
  min-width: 64px;
}

.quick-search-container div.action label {
  border-radius: 0 0.25em 0.25em 0;
  border-right: 1px solid #ced4da;
  display: flex;
  justify-content: center;
  min-width: 2em;
  width: 100%;
}

.search-widget-action {
  cursor: pointer;
}

.search-widget-action:hover {
  background: #aeb5be;
  cursor: pointer;
}
