.flex-skeleton {
  background-color: #343a40;
  display: inline-flex;
  flex: 1;
}

@for $i from 1 through 12 {
  .shift-right-#{$i} {
    margin-left: $i + px !important;
  }
}


@for $i from 1 through 12 {
  .shift-up-#{$i} {
    margin-top: -$i + px !important;
  }
}

@for $i from 1 through 12 {
  .shift-down-#{$i} {
    margin-top: $i + px !important;
  }
}

.justify-content-left {
  justify-content: left;
}

.justify-content-right {
  justify-content: right;
}

.colour-green {
  color: #080;
}

@for $i from 1 through 12 {
  .min-width-#{$i} {
    min-width: 4 * $i + em !important;
  }
}
