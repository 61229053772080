$header-title-font-size: 1.2em;
$zindex-fixed-side-panel: $zindex-fixed+1;

.side-panel {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: $zindex-fixed-side-panel;
  @include media-breakpoint-up(md) {
    .side-panel-header {
      width: 70%;
    }

    .side-panel-container {
      width: 70%;
    }
  }
  @include media-breakpoint-only(xl) {
    .side-panel-header {
      width: 50%;
    }

    .side-panel-container {
      width: 50%;
    }
  }
}

.side-panel::after {
  content: '';
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.side-panel-visible {
  visibility: visible;
}

.side-panel-visible::after {
  background: $black;
  opacity: 0.6;
}

.side-panel-header {
  background: $gray-100;
  height: 50px;
  line-height: 50px;
  position: fixed;
  right: 0;
  top: 0;
  width: 90%;
  z-index: $zindex-fixed;
}

.side-panel-title {
  font-size: $header-title-font-size;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  h1 {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

.side-panel-container {
  background: $body-bg;
  border-left: 1px solid $gray-200;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 90%;
  z-index: $zindex-sticky;
}

.side-panel-content {
  height: 100%;
  left: 0;
  overflow: auto;
  padding: 70px 5%;
  position: absolute;
  top: 0;
  width: 100%;
}
