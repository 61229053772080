div.export-widget {
  border-radius: 2px;
  padding-bottom: 4px;
  padding-left: 2em;
}

div.export-row-header {
  align-content: center;
  display: flex;
  flex-direction: row;
}

div.export-button-container {
  margin-top: -5px;
}

span.export-row-header-element {
  align-self: center;
  display: flex;
  padding-right: 8px;
}

.export-widget-icon-case {
  color: #0073e6;
  margin-top: -2px;
}

.export-widget-icon-task {
  color: #64cc21;
  margin-top: -2px;
}

.export-button:hover {
  background: #d1d4d9;
}

.export-button-icon {
  color: #6a9ace;
  min-height: 24px;
  min-width: 24px;
  padding-right: 2px;
}

