$report-border-colour: #ddd;

.reports {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 0.5em;
  width: 100%;

  .panel {
    background-color: #fff;
    border: 1px solid $report-border-colour;
    border-radius: 0.25em;
    display: flex;
    flex-direction: column;
    margin: 0.5em;
    padding: 0.5em;
    width: 100%;

    .section-header {
      align-content: center;
      border-bottom: 1px solid #ccc;
      display: flex;
      flex-direction: row;
      margin-bottom: 1.5em;
      min-height: 2em;
      width: 100%;
    }

    .section-header-icon {
      color: #9ea8a9;
      margin-top: 3px;
      max-height: 36px;
      min-width: 24px;
      padding-right: 4px;
    }

    .content {
      display: flex;
      flex-direction: column;
    }

    div.state-debug {
      column-gap: 0.25em;
      display: flex;
      flex-direction: row;
      min-height: 240px;
      width: 100%;
    }

    div.state-debug-col {
      color: #2a9445;
      display: flex;
      width: 100%;
    }

    div.state-debug-col > * {
      background-color: #1c0b2a;
      color: #2a9445;
      max-height: 48em;
      padding: 0.5em;
      width: 100%;
    }
  }

  .template-editor {
    display: flex;
    flex-direction: column;
    margin-top: 0.5em;

    .collapsible {
      border: 1px solid $report-border-colour;
      border-radius: 0.25em;
      margin-bottom: 0.5em;
      padding: 0.45em 1em 0.25em;
    }

    .collapsible-header {
      padding-bottom: 0.25em;
    }

    div.tab-content {
      column-gap: 0.25em;
      display: flex;
      flex-direction: column;

      div.tab-content-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.25em;
      }
    }

    table {
      border-left-style: none;
      border-right-style: none;
      border-top-style: none;
      font-size: smaller;
      max-height: 640px;

      th[role='columnheader'] {
        border-left: 0.5px solid #eee;
        font-size: small;
      }

      tr[role='rowheader'] {
        background: #fff;
      }

      tr input.column-label {
        border: 1px solid #ced4da;
        border-radius: 0 0.25em 0.25em 0;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
        display: flex;
        flex: 1;
        font-size: smaller;
        padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      }

      tr.row-select-selected {
        background: #fff;
        border-bottom: 2px solid #25a122;
        border-top: 2px solid #25a122;
        margin-left: -0.5em;

        td:first-of-type {
          border-left: 4px solid #25a122;
        }

        td:last-of-type {
          border-right: 4px solid #25a122;
        }
      }

      tr:not(.row-select-selected) {
        background: #fff;

        td:first-of-type {
          border-left: 6px solid transparent;
        }
      }
    }
  }

  .report-form {
    display: flex;
    flex-direction: column;
    row-gap: 1em;

    .report-form-divider {
      align-self: center;
      display: flex;
      width: 98%;
    }

    .report-form-row-spacer {
      font-weight: bold;
      margin-top: -2px;
    }

    .report-form-row {
      align-content: center;
      align-items: center;
      column-gap: 1em;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 1em;
      padding-right: 1em;

      .heading {
        font-weight: bold;
      }

      .report-form-label {
        display: flex;
      }

      .report-form-field {
        align-self: center;

        select {
          border: 1px solid #ced4da;
          border-radius: 0 0.25em 0.25em 0;
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
          display: flex;
          flex: 1;
          padding: 0.375rem 1.75rem 0.375rem 0.75rem;
        }
      }
    }

    .report-form-row-grid>* {
      flex: 1 0 35%;
    }
  }
}

li[role='tab'] {
  background: transparent;
  border-color: $report-border-colour;
  font-size: smaller;
}

li[role='tab'][aria-selected='true'] {
  background: transparent;
  border-color: #369a31;
  border-width: 0.25em;
  font-size: smaller;
  font-weight: bold;
}

div[role='tabpanel'] {
  border-bottom: 1px solid $report-border-colour;
  border-left: 1px solid $report-border-colour;
  border-right: 1px solid $report-border-colour;
  margin-top: -12px;
  padding: 1.5em 1em 0.5em;
}

.report-tabs {
  border-color: $report-border-colour;
}

.filter-panel {
  display: flex;
}

.right-justified-content {
  justify-content: right;
}

.left-justified-content {
  justify-content: left;
}

.included-form-tick {
  color: #1c7430;
  padding-left: 4px;
}

.excluded-form-cross {
  color: #9a121c;
  padding-left: 4px;
}

.tab-icon {
  color: #6c757d;
  margin-top: -3px;
  padding-right: 4px;
}
