$box-shadow-lift: 0 1rem 2.5rem fade-out($black, 0.9), 0 0.5rem 1rem -0.75rem fade-out($black, 0.9) !default;
$box-shadow-lift-lg: 0 2rem 5rem fade-out($black, 0.9), 0 0.5rem 1rem -0.75rem fade-out($black, 0.95) !default;

//
// lift.scss
// Theme utility
//

.lift {
  transition: box-shadow 0.25s ease, transform 0.25s ease;
}

.lift:hover,
.lift:focus {
  box-shadow: $box-shadow-lift !important;
  transform: translate3d(0, -3px, 0);
}

.lift-lg:hover,
.lift-lg:focus {
  box-shadow: $box-shadow-lift-lg !important;
  transform: translate3d(0, -5px, 0);
}
