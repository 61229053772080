$img-skewed-rotate-y: 35deg !default;
$img-skewed-rotate-x: 15deg !default;

//
// Skewed
//

.img-skewed {
  perspective: 1500px;
  transform-style: preserve-3d;
}

// Left

.img-skewed-left {
  perspective-origin: left center;

  .img-skewed-item {
    backface-visibility: hidden;
    transform: rotateY(-$img-skewed-rotate-y) rotateX($img-skewed-rotate-x);
  }
}

// Right

.img-skewed-right {
  perspective-origin: right center;

  .img-skewed-item {
    backface-visibility: hidden;
    transform: rotateY($img-skewed-rotate-y) rotateX($img-skewed-rotate-x);
  }
}
