.widget-container {
  align-content: space-between;
  display: flex;
  flex-direction: column;
}

.widget-container-row {
  align-content: space-between;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.widget {
  align-content: center;
  align-items: center;
  align-self: center;
  display: inline-flex;
  flex-direction: row;
  justify-items: left;
  margin-bottom: 0.25em;
  min-width: 0;
  padding: 0;

  &.action {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
    filter: opacity(35%);
  }

}

.widget-action {
  cursor: pointer;
  overflow: hidden;
}

.action-search-svg {
  min-width: 12px;
  width: 24px;
}

.action-svg {
  width: 24px;
}

.widget:not(:first-child) {
  border-left: 0;
}

.widget:not(:last-child) {
  border-right: 0;
}

.widget-calendar {
  z-index: 999;
}

.widget-popper {
  z-index: 999;
}

.widget-justify-right {
  justify-self: right;
}

.widget-justify-left {
  justify-self: left;
}

.widget-label {
  flex-basis: auto;
  font-size: smaller;
  min-width: 4em;
  overflow-y: auto;

  &.no-right-border {
    border-bottom-right-radius: 0;
    border-right: 0;
    border-top-right-radius: 0;
  }

  &.no-left-border {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0;
  }

}

.widget-field {
  border: 1px solid #ced4da;
  border-radius: 0 0.25em 0.25em 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  display: flex;
  flex: 1;
  font-size: smaller;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;

  &.no-right-border {
    border-bottom-right-radius: 0;
    border-right: 0;
    border-top-right-radius: 0;
  }

  &.no-left-border {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0;
  }

  &:disabled {
    filter: opacity(55%);
  }
}

input.widget-field::placeholder {
  color: #333;
}

.widget-button {
  cursor: pointer;
  display: flex;
  flex-basis: auto;
  font-size: smaller;
  height: 100%;
  overflow-y: auto;

  &:hover {
    filter: opacity(75%);
  }

  &.disabled {
    cursor: not-allowed;
    filter: opacity(35%);
  }

  svg {
    min-width: 24px;
    padding-left: 4px;
  }
}

.widget-checkbox-input {
  margin-top: -7px;
}

.widget-checkbox-label {
  cursor: pointer;
  font-size: smaller;
  padding-left: 0.5em;
}

input::placeholder {
  color: #cc4040;
}
