$header-height: 64px;
$sidebar-width: 300px;

// Header
////////////////////

.app-navbar {
  background: $body-bg;
  border: 0;
  border-bottom: 2px solid $border-color;
  border-radius: 0;
  min-height: $header-height;

  .header-container {
    justify-content: start;
  }

}

.page-title {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 400px;
}

.sub-nav {
  .btn {
    margin: 2px 0;
  }
}

// Content
////////////////////

#content {
  max-width: 100%;
  outline: 0;
  transition: all 0.3s;

  hr {
    margin: 0 0 10px;
  }

}

.modal-content {
  hr {
    margin: 0 0 10px;
  }

  h1 {
    margin: 0;
  }

}

// Full screen view
////////////////////
.fullscreen-container {
  background: $body-bg;
  bottom: 0;
  left: 0;
  margin-top: $header-height;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;

  .navbar-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  h1 {
    display: inline;
  }

  .navbar-options-container {
    position: relative;
    white-space: nowrap;

    .btn {
      margin-bottom: 2px;
    }
  }
}

.close-btn {
  background-color: $secondary;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  text-indent: 100%;
  top: 0;
  white-space: nowrap;
  width: 60px;
}

.close-btn-full-screen {
  border: 0;
  left: auto;
  outline: none !important;
  right: 0;
}

.close-btn::before,
.close-btn::after {
  backface-visibility: hidden;
  background-color: $white;
  content: '';
  height: 3px;
  left: 20px;
  position: absolute;
  top: 22px;
  width: 20px;
}

.close-btn-full-screen::before,
.close-btn-full-screen::after {
  left: 24px;
  top: 29px;
  width: 20px;
}

.close-btn::before {
  transform: rotate(45deg);
}

.close-btn::after {
  transform: rotate(-45deg);
}

.close-btn:hover {
  background-color: $gray-900;
}
