// Sidebar
////////////////////

$sidebar-font-size: 16px;
$sidebar-width: 320px;
$sidebar-logo-height: 40px;
$avatar-container-width: 40px;
$logout-container-width: 80px;
$backgound-color: #fff;
$customer-logo-border-color: #888;

#sidebar-overlay {
  height: 1px;
  left: -10px;
  opacity: 0;
  position: fixed;
  top: -10px;
  transition: opacity ease 0.5s;
  width: 1px;
  z-index: -1;
}

#sidebar-overlay.enabled {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 1;
  right: 0;
  top: 0;
  width: 100%;
}

#sidebar {
  background: $light;
  border-right: 1px solid $border-color;
  color: $body-color;
  height: 100vh;
  min-width: $sidebar-width; // without will contract on smaller screens
  transition: all ease 0.2s;
  width: $sidebar-width;
  @include media-breakpoint-up(md) {
    left: 0;
    position: relative !important;

    &.active {
      left: 0;
    }
  }
  @include media-breakpoint-down(md) {
    bottom: 0;
    left: -$sidebar-width;
    position: absolute !important;
    top: 0;
    z-index: $zindex-modal;

    &.active {
      left: 0;
    }
  }

  a:link {
    text-decoration: none;
  }

  .sidebar-nav {
    background: $backgound-color;
    flex-grow: 1;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;

    li:not(:first-child) .nav-link {
      font-size: $sidebar-font-size;
    }
  }

  .sidebar-nav > ul > li {
    width: 100%;
  }

  .nav {
    margin-bottom: 0.75rem;

    .nav-link {
      cursor: pointer;
      padding-left: 1rem;
      transition: all ease 0.1s;

      &:hover,
      &:focus {
        background: $body-bg;
        padding-left: 1.2rem;
      }

      &.active {
        background: $body-bg;
        cursor: default;
        font-weight: bold;
        padding-left: 1rem;
      }
    }
  }

  .logo-container {
    background: $backgound-color;
    border-bottom: 1px solid $border-color;
    min-height: $header-height;
    overflow: hidden;

    .customer-logo {
      border: 1px solid $customer-logo-border-color;
      border-radius: 50%;
      height: $sidebar-logo-height;
      padding: 5px;
      position: absolute;
      top: 12px;
      vertical-align: middle;
      width: $sidebar-logo-height;
    }
  }
}

// Sidebar user profile
///////////////////////

.user-profile-container {
  background: $backgound-color;
  border-top: 1px solid $border-color;

  .avatar-container {
    text-align: left;
    width: $avatar-container-width;
  }

  .logout-container {
    width: $logout-container-width;
  }

  .overflow-container {
    display: flex;
    max-width: 100%;
    padding: 0 !important;
    position: relative;
    vertical-align: text-bottom !important;
  }

  .ellipsis {
    left: 0;
    max-width: 100%;
    min-width: 0;
    overflow-x: hidden;
    overflow-y: visible;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;
    white-space: nowrap;
    width: 100%;
  }

  .overflow-container::after,
  .ellipsis::after {
    content: '&nbsp';
    display: inline;
    visibility: hidden;
  }

  .btn-logout {
    width: 40px;
  }
}

.sidebar-toggler {
  background: transparent !important;
  border: 0;
  width: 40px;

  .icon-bar {
    background-color: $primary;
    display: block;
    height: 2px;
    margin-top: 4px;
    transition: all 0.4s;
    width: 22px;

    &.top-bar {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
    }

    &.middle-bar {
      opacity: 0;
    }

    &.bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
    }
  }

  &.collapsed .icon-bar {
    &.top-bar {
      transform: rotate(0);
    }

    &.middle-bar {
      opacity: 1;
    }

    &.bottom-bar {
      transform: rotate(0);
    }
  }
}
