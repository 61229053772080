.field-diff-container {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  font-weight: bolder;
}

.field-diff-container .list-additions {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.field-diff-container .list-removals {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.field-diff-container .sidebar {
  align-self: flex-start;
  color: #444;
  display: flex;
  font-size: 24px;
  font-weight: normal;
  margin-top: 1px;
  padding-right: 0.25em;
}

.field-diff-container .sidebar svg {
  background: transparent;
  filter: invert(100%) !important;
  height: 0.8em !important;
  width: 0.8em !important;
}

.field-diff-container .sidebar.inner {
  color: transparent;
  display: flex;
  font-size: 24px;
  font-weight: normal;
  padding-right: 0.25em;
}

.field-diff-container .add {
  background: transparent;
  border-left: 6px solid #2a9445;
  display: flex;
  height: 100%;
  margin-bottom: 0.25em;
  padding-left: 0.25em;
  width: 100%;
}

.field-diff-container .remove {
  background: transparent;
  border-left: 6px solid #ab1b31;
  display: flex;
  height: 100%;
  margin-bottom: 0.25em;
  padding-left: 0.25em;
  width: 100%;
}
