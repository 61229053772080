.traffic-light-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 5em;
  justify-content: space-between;
  padding-left: 1em;
  padding-right: 1em;

  .member {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding-left: 0.5em;
    padding-right: 0.5em;

    .light {
      display: flex;
      padding-right: 0.5em;

      svg {
        height: 1.5em;
        width: 1.5em;
      }
    }

    .label {
      display: flex;
    }
  }

  .unselected {
    filter: saturate(55%);
  }

  .red {
    color: #b60b10;

    &:hover {
      filter: saturate(100%);
    }
  }

  .amber {
    color: #e7b100;

    &:hover {
      filter: saturate(100%);
    }
  }

  .green {
    color: #068e1c;

    &:hover {
      filter: saturate(100%);
    }
  }
}
