nav.header {
  border: 1px;
  display: flex;
  flex-direction: row;
}

@media (max-width: 800px) {
  nav.header {
    flex-direction: column;
  }
}

nav.header .navbar-toggle {
  display: flex;
  justify-self: left;
  width: 100%;
}

nav.header .navbar-brand {
  display: flex;
  justify-self: left;
}


@media (max-width: 800px) {
  nav.header .navbar-brand {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

nav.header .navbar-children {
  display: flex;
  padding-right: 0.25em;
}

nav.header .navbar-search {
  display: flex;
  justify-self: right;
}
