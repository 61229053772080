.cookie-popup {
  animation: cookie-jump 4s ease-in-out infinite;
  border-radius: $border-radius;
  bottom: 10px;
  box-shadow: -20px 20px 30px rgba(0, 0, 0, 0.6);
  font-size: $font-size-sm;
  left: 10px;
  line-height: 1.2;
  padding: $alert-padding-y $alert-padding-x;
  position: fixed;
  width: 320px;
  z-index: 99999;
}

@keyframes cookie-jump {
  0% { bottom: 10px; }
  15% { bottom: 10px; }
  20% { bottom: 20px; }
  25% { bottom: 10px; }
  100% { bottom: 10px; }
}
