$img-skewed-rotate-y: 35deg !default;
$img-skewed-rotate-x: 15deg !default;

@media (min-width: map-get($grid-breakpoints, 'lg')) {
  .img-skewed-item-left {
    transform: rotateY(-$img-skewed-rotate-y) rotateX($img-skewed-rotate-x) translate3d(0, 0, 0);
  }

  .img-skewed-item-right {
    transform: rotateY($img-skewed-rotate-y) rotateX($img-skewed-rotate-x) translate3d(0, 0, 0);
  }
}
