$font-family-sans-serif: 'Roboto', sans-serif;

$primary: #67299f;
$enable-gradients: true;
$enable-rounded: true;
$enable-shadows: true;
$text-muted: #666f76;
$text-secondary: #444;
$text-placeholder: #333;

$nav-title-font-size: 1.5em;
$comments-avatar-width: 30px;
$comments-avatar-height: 30px;
$form-flow-modal-min-height: 545px;
$feature-card-min-height: 250px;

$table-caption-font-size: 0.9em;
$table-caption-font-color: #000;
$table-caption-border-background: #f9f9f9;
$table-caption-border-color: #dee2e6;

$badge-border-radius: 15px;
$table-background-color: #fff;
$table-header-background-color: #f9f9f9;
$table-header-color: #555;
$table-shadow-color: #eee;
$table-header-font-size: 18px;
$card-shadow-color: #eee;
$pagination-nav-color: #fff;
$pagination-nav-background-color: #f1f1f1;
$pagination-link-color: #212121;
$pagination-active-background-color: #fff;

$h1-font-size: 1.5rem;
$h2-font-size: 1.3rem;
$h3-font-size: 1.2rem;
$h4-font-size: 1.1rem;
$h5-font-size: 1rem;
$h6-font-size: 1rem;

// reduce display sizes as we do not need such large ones
$display1-line-height: 1.2 !default;
$display2-line-height: 1.2 !default;
$display3-line-height: 1.2 !default;
$display4-line-height: 1.2 !default;
$display-letter-spacing: -0.02em !default;
$display1-size-md: 3.125rem !default; // 48px
$display2-size-md: 2.5rem !default; // 38px
$display3-size-md: 2.25rem !default; // 36px
$display4-size-md: 2rem !default; // 32px

@import '../../node_modules/react-tabs/style/react-tabs';
@import '~bootstrap/scss/bootstrap';
@import 'navbar';
@import 'buttons';
@import 'badges';
@import 'case';
@import 'dashboard';
@import 'loaders';
@import 'cookies';
@import 'layout';
@import 'sidebar';
@import 'sidepanel';
@import 'additionalColoringStyles';
@import 'form';
@import 'search';
@import 'images';
@import 'collapsible';
@import 'activities';
@import 'tasks';
@import 'exports';
@import 'reports';
@import 'links';
@import 'misc';
@import 'history';
@import 'widgets';
@import 'traffic-light';
@import 'theme/shapes';
@import 'theme/utilities/images';
@import 'theme/utilities/lift';

* {
  font-family: $font-family-sans-serif;
}

*:focus-visible {
  box-shadow:
    0 0 0 1px #fff,
    0 0 0 4px #0051ff !important;
  outline-color: transparent !important;
  outline-style: solid !important;
  z-index: 99999;
}

.navbar-brand {
  font-size: $nav-title-font-size;

  h1 {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: inherit;
  }
}

.about-card-height {
  min-height: 200px;
}

.card {
  border-radius: 0;
  box-shadow: 0 0 10px $card-shadow-color;

  .list-group {
    .list-group-item {
      border: 0;
      border-bottom: 1px solid $border-color;
    }
  }

  .card-header {
    padding-bottom: 0.3rem;
  }
}

.table-container {
  background: $table-background-color;
  border-radius: 0;
  box-shadow: 0 0 10px $table-shadow-color;

  caption {
    background: $table-caption-border-background;
    border: 1px solid $table-caption-border-color;
    color: $table-caption-font-color;
    font-size: $table-caption-font-size;
    padding-bottom: 0.4rem;
    padding-left: 12px;
    padding-top: 0.4rem;
    text-align: left;
  }

  table th {
    background: $table-header-background-color;
    color: $table-header-color;
    position: relative;

    a {
      color: $primary;
      padding-right: 20px;
      text-decoration: underline;
    }

    a:hover {
      color: $table-header-color;
    }
  }

  .th-sorted::after {
    color: $table-header-color;
    content: ' \2193\2191';
    font-size: $table-header-font-size;
    margin-left: -20px;
    margin-top: -4px;
    position: absolute;
    white-space: pre;
  }
}

.pagination {
  padding-bottom: 10px;

  .page-item.active .page-link {
    background-color: $primary;
    color: $pagination-active-background-color;
  }

  .page-item .page-link {
    background-color: transparent;
    color: $pagination-link-color;
    display: block;
    font-size: $small-font-size;
    font-weight: 500;
    line-height: 1.25;
    margin-left: -1px;
    padding: 0.5rem 0.95rem;
    position: relative;
  }

  .page-item.disabled {
    background-color: $pagination-nav-background-color;
    color: $pagination-nav-color;
  }
}

.badge {
  border-radius: $badge-border-radius;
  display: inline-block;
  font-size: $small-font-size;
  font-weight: normal;
  padding: 4px 10px;
}

.nav-item-divider {
  background: $border-color;
  height: 1px;
  margin-left: -15px;
  margin-right: -15px;
}

[data-href] {
  cursor: pointer;
}

[data-href]:hover,
[data-href]:focus {
  background-color: rgba(0, 0, 0, 0.05);
}

.data-table {
  margin: 0.75rem 1.25rem;
}

dt,
dd {
  margin-bottom: 0.5rem;
}

/* stylelint-disable selector-class-pattern */
.Toastify__toast.Toastify__toast--warning {
  color: #000;
  font-size: 0.9em;
  padding: 0.9em;
}

.Toastify__toast-container {
  .Toastify__toast--default {
    background: $body-bg;
    padding: 0;

    .Toastify__toast-body {
      color: $body-color;
    }
  }
}
/* stylelint-enable selector-class-pattern */

.avatar {
  cursor: pointer;
}

.comments-avatar {
  min-height: $comments-avatar-height;
  min-width: $comments-avatar-width;
}

.watch-body {
  min-height: 470px;
}

.white-space-pre-line {
  white-space: pre-line;
}

.comments-table {
  width: 100%;

  .ellipsis {
    position: relative;
  }

  .ellipsis::before {
    content: '&nbsp;';
    visibility: hidden;
  }

  .ellipsis span {
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .avatar-column {
    width: $comments-avatar-width;
  }

  .time-column {
    min-width: 30px;
    text-align: right;
  }

  .remove-column {
    padding-left: 10px;
    width: 30px;
  }
}

.diff-title {
  font-size: $small-font-size;
  text-decoration: underline;
}

.feature-card {
  min-height: $feature-card-min-height;
}

// hubspot chat widget
#hubspot-messages-iframe-container {
  margin-right: 16px;
}

.skip-link {
  background: #fd0;
  color: #000;
  font-size: $small-font-size;
  padding: 6px;
  position: absolute;
  transform: translateX(-300%);
  transition: transform 0.3s;
}

.skip-link:focus {
  margin-top: 2px;
  transform: translateX(0%);
}

.btn-outline-secondary {
  color: $text-secondary;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: $text-muted;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.9;
}

.btn-outline-danger {
  color: $text-secondary;
}

.download-dropdown {
  height: 100%;

  button {
    background: transparent;
    border-color: #dee2e6;
    box-shadow: none;
    color: #000;
  }

  button:disabled {
    background: transparent;
    border-color: #dee2e6;
    color: #808080;
  }
}

.loading-animation {
  animation: rotation 1s linear infinite;
  border: 3px solid rgb(34, 34, 34);
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  height: 30px;
  width: 30px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: #fc6147;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 0.55em;
  font-weight: bold;
}

/* stylelint-disable selector-no-vendor-prefix */

input::-webkit-input-placeholder {
  color: $text-placeholder !important;
  opacity: 1 !important;
}

input::-moz-placeholder {
  color: $text-placeholder !important;
  opacity: 1 !important;
}

input:-ms-input-placeholder {
  color: $text-placeholder !important;
  opacity: 1 !important;
}

input::placeholder {
  color: $text-placeholder !important;
  opacity: 1 !important;
}

a:link {
  text-decoration: underline;
}

a.btn {
  text-decoration: none;
}

/* stylelint-enable selector-no-vendor-prefix */
