ul.unavailable-task-item {
  font-size: smaller;
  font-weight: bold;
  list-style-type: none;
  margin-left: -8px;
}

button.non-selectable-item {
  background-color: #828a91;
}

.unselectable-task-icon {
  color: #bb2742;
  margin-top: -2px;
  padding-right: 4px;
}

.selectable-task-icon {
  color: #64cc21;
  margin-top: -4px;
  padding-right: 4px;
}

.referenced-case-row {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
}





